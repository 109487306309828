import {
  CreateDonationProps,
  CreateDonationResponse
} from 'modules/donation/use-cases/CreateDonation/types';
import { DonationPaginateResponse } from 'modules/donation/use-cases/PaginateDonations/types';
import { DonationView } from 'modules/donation/use-cases/GetDonation/types';
import { FetchService } from './FetchService';
import { FilterParams } from 'modules/donation/repos/DonationRepo';
import { UpdateDonationPaymentProps } from 'modules/donation/use-cases/UpdateDonationPayment/types';

export const createDonation = (props: CreateDonationProps) =>
  FetchService<CreateDonationResponse, CreateDonationProps>(
    'POST',
    `/api/donations`,
    props
  );

export const updateDonationPaymentDetails = (
  id: string,
  props: UpdateDonationPaymentProps
) =>
  FetchService<void, UpdateDonationPaymentProps>(
    'PUT',
    `/api/donations/${id}/payment/`,
    props
  );

export const getDonation = (url: string) =>
  FetchService<DonationView>('GET', url);

export const paginateDonations = (request: FilterParams) =>
  FetchService<DonationPaginateResponse, FilterParams>(
    'GET',
    `/api/donations/`,
    request
  );
