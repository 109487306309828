import { ChangeEvent, useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { FormFieldProps } from './types';
import tw from 'tailwind-styled-components';

export interface TextAreaProps extends FormFieldProps {
  rows: number;
  disabled?: boolean;
  maxlength?: number;
  placeholder?: string;
}
type StyledFieldProps = {
  showerror?: string;
  value?: string;
  name: string;
  rows: number;
  disabled?: boolean;
  maxlength?: number;
};
const StyledField = tw.textarea<StyledFieldProps>`
  ${(props: StyledFieldProps) =>
    props.showerror
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500'
      : 'border-gray-300 shadow-sm focus:border-ros-blue'}
  block w-full rounded-md  pr-10  focus:outline-none  sm:text-sm max-h-80
`;

const TextArea = (props: TextAreaProps) => {
  const [value, setValue] = useState(props.value || '');
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const onChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, ev.target.value, false);
    }
    if (props.setFieldTouched) {
      props.setFieldTouched(props.name, true, false);
    }
    setValue(ev.target.value);
    if (props.hasError && props.isDirty && props.validate) {
      props.validate(props.name);
    }
  };
  const onBlur = () => {
    if (props.validate) {
      props.validate(props.name);
    }
  };
  return (
    <div>
      <label htmlFor={props.name} className="block text-sm font-medium">
        {props.label}{' '}
        {props.maxlength && (
          <span className="text-xs text-red-500">
            (Character limit: {props.maxlength})
          </span>
        )}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <StyledField
          name={props.name}
          value={value}
          // showerror={(props.hasError && props.isDirty) || false}
          showerror={props.hasError && props.isDirty ? 'true' : undefined}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e)}
          onBlur={onBlur}
          rows={props.rows || 4}
          disabled={props.disabled || false}
          maxLength={props.maxlength}
          {...(props.placeholder && { placeholder: props.placeholder })}
        />
        {props.hasError && props.isDirty && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {props.hasError && props.isDirty ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.errorMessage || `${props.label} is invalid`}
        </p>
      ) : null}
    </div>
  );
};

export default TextArea;
