import {
  deleteUploadedFile,
  getPresignedUrl,
  getUploadUrl
} from '../services/s3';
import axios from 'axios';

interface S3UploadProps {
  bucket: string;
  expires?: number;
  contentType?: string;
}

interface UseS3UploadReturnType {
  uploadFile: (file: File, key: string) => Promise<string>;
  getFileUrl: (key: string) => Promise<string>;
  deleteFile: (key: string) => Promise<void>;
  cdnEndpoint?: string;
}

const cdnEndpoint = process.env.NEXT_PUBLIC_S3_CDN_ENDPOINT;

export const useS3Upload = (props: S3UploadProps): UseS3UploadReturnType => {
  const getPresignedUploadUrl = async (key: string, contentType: string) => {
    return await getUploadUrl({
      ...props,
      key,
      contentType
    });
  };

  const uploadFile = async (file: File, key: string): Promise<string> => {
    const uploadUrl = await getPresignedUploadUrl(key, file.type);
    await axios({
      url: uploadUrl!,
      method: 'put',
      data: file,
      headers: {
        'Content-Type': file.type,
        'Cache-Control': 'public,max-age=31536000,immutable',
        'x-amz-acl': 'public-read'
      },
      maxContentLength: 100 * 1024 * 1024 * 1024,
      timeout: 30 * 60 * 1000 //30mins
    });
    const previewUrl = await getFileUrl(key);
    return previewUrl;
  };

  const getFileUrl = async (key: string): Promise<string> => {
    if (cdnEndpoint) {
      return `${cdnEndpoint}/${key}`;
    }
    const url = await getPresignedUrl({
      ...props,
      key
    });
    return url;
  };

  const deleteFile = async (key: string) => {
    await deleteUploadedFile({
      ...props,
      key
    });
  };

  return {
    uploadFile,
    getFileUrl,
    deleteFile,
    cdnEndpoint
  };
};
