import { FormFieldProps } from './types';
import { classNames } from '../../utils/string';

export interface RadioGroupProps extends FormFieldProps {
  radioGroup: { title: string; value: any }[];
  label: string;
  description?: string;
}

const RadioGroup = (props: RadioGroupProps) => {
  const { radioGroup, label, description } = props;

  const labelStyles = classNames(
    props.hasError && props.isDirty ? 'text-red-900' : 'text-trhp-gray-100',
    'text-sm font-light'
  );

  const descriptionStyles = classNames(
    props.hasError && props.isDirty ? 'text-red-500' : 'text-gray-500',
    'text-sm leading-5'
  );

  const handleOnClick = (event: any) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, event.target.value, true);
    }
    if (props.setFieldTouched) {
      props.setFieldTouched(props.name, true, true);
    }
  };

  return (
    <div>
      <label className={labelStyles}>{label}</label>
      <p className={descriptionStyles}>{description}</p>
      <fieldset className="mt-2">
        <legend className="sr-only">Notification method</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
          {radioGroup.map(radio => (
            <div key={radio.title} className="flex items-center">
              <input
                id={radio.title}
                name="notification-method"
                type="radio"
                value={radio.value}
                defaultChecked={props.value === radio.value}
                className="h-4 w-4 border-gray-300 text-trhp-teal focus:ring-transparent"
                onClick={e => handleOnClick(e)}
              />
              <label
                htmlFor={radio.title}
                className="ml-1 block text-sm font-medium text-trhp-gray-100"
              >
                {radio.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {props.hasError && props.isDirty ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.errorMessage || `${props.label} is invalid`}
        </p>
      ) : null}
    </div>
  );
};

export default RadioGroup;
