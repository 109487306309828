const roundToTwo = (num: number): number => {
  //@ts-ignore
  return +(Math.round(num + 'e+2') + 'e-2');
};

export const calculateFee = (amount: number): number => {
  const total = (amount + 0.3) / (1 - 2.2 / 100);
  const fee = total - amount;
  return roundToTwo(fee);
};
