import { ReactElement, useEffect, useState } from 'react';
import { parseHtml } from 'utils/html';

export const useHtmlParser = (html: string) => {
  const [parsed, setParsed] = useState<string | ReactElement | ReactElement[]>(
    html
  );
  const startParse = async (html: string) => {
    const parsedHtml = await parseHtml(html);
    setParsed(parsedHtml);
  };
  useEffect(() => {
    startParse(html);
  }, [html]);
  return parsed;
};
