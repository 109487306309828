import { AiFillInfoCircle } from 'react-icons/ai';
import { DonationProps } from './types';
import { FixedArrowButton } from 'components/button/FixedArrowButton';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { calculateFee } from 'utils/payments';
import { createDonation } from 'services/donations';
import Checkbox from 'components/forms/Checkbox';
import RadioGroup from 'components/forms/RadioGroup';
import React, { useEffect, useState } from 'react';
import Select from 'components/forms/Select';
import TextArea from 'components/forms/TextArea';
import TextField from 'components/forms/TextField';
import usStates from './us-states.json';

interface PaymentFormProps {
  data: DonationProps;
  goBack: () => void;
  slug?: string;
  ctaColor?: string;
}

export const PaymentForm = (props: PaymentFormProps) => {
  const { data, goBack } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    comment: '',
    isCompany: 'false',
    anonymous: false,
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    howDidYouHear: '',
    howDidYouHearOther: '',
    ...data
  };
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>({});
  const [dirty, setDirty] = useState<any>({});

  const transactionFee = calculateFee(data.amount);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);
  const submitPayment = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const valid = validateForm();
    if (!valid) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessage(submitError.message);
      setIsLoading(false);
      return;
    }
    //save the donation details first
    let donation;
    try {
      donation = await createDonation({
        ...values,
        total: data.total!,
        coverFees: data.coverFees,
        isCompany: Boolean(values.isCompany === 'true'),
        campaignId: data.campaignId
      });
      if (!donation || !donation.id) {
        setMessage(
          'There was a problem saving your donation. Please try again.'
        );
        setIsLoading(false);
        return false;
      }
    } catch (err) {
      setMessage('There was a problem saving your donation. Please try again.');
      setIsLoading(false);
      return false;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${
          process.env.APP_URL || process.env.NEXT_PUBLIC_APP_URL || ''
        }/${props.slug ? props.slug : 'give/thank-you'}?id=${donation.id}`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'accordion'
  };

  const setFieldValue = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setDirty({ ...dirty, [field]: true });
    validateField(field, value);
    if (elements) {
      const paymentElement = elements.getElement('payment');
      if (field === 'companyName') {
        if (paymentElement)
          paymentElement.update({ business: { name: value } });
      } else if (field === 'firstName' || field === 'lastName') {
        if (paymentElement)
          paymentElement.update({
            defaultValues: {
              billingDetails: {
                email: values.email,
                name: `${values.firstName} ${values.lastName}`
              }
            }
          });
      } else if (field === 'email') {
        if (paymentElement)
          paymentElement.update({
            defaultValues: {
              billingDetails: {
                email: value,
                name: `${values.firstName} ${values.lastName}`
              }
            }
          });
      }
    }
  };

  const setFieldError = (field: string) => {
    setErrors({ ...errors, [field]: true });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { ...errors };
    let newDirty = { ...dirty };
    if (values.lastName === '') {
      valid = false;
      newDirty.lastName = true;
      newErrors.lastName = 'Last name is required';
    }
    if (values.firstName === '') {
      valid = false;
      newDirty.firstName = true;
      newErrors.firstName = 'First name is required';
    }
    if (values.email === '') {
      valid = false;
      newDirty.email = true;
      newErrors.email = 'Email is required';
    }
    if (values.address === '') {
      valid = false;
      newDirty.address = true;
      newErrors.address = 'Address is required';
    }
    if (values.city === '') {
      valid = false;
      newDirty.city = true;
      newErrors.city = 'City is required';
    }
    if (values.state === '') {
      valid = false;
      newDirty.state = true;
      newErrors.state = 'State is required';
    }

    if (values.zip === '') {
      valid = false;
      newDirty.zip = true;
      newErrors.zip = 'Zip code is required';
    }
    if (values.phone === '') {
      valid = false;
      newDirty.phone = true;
      newErrors.phone = 'Phone is required';
    }
    if (values.howDidYouHear === '') {
      valid = false;
      newDirty.howDidYouHear = true;
      newErrors.howDidYouHear = 'Please tell us how you heard about us?';
    }

    setDirty(newDirty);
    setErrors(newErrors);

    return valid;
  };

  const validateField = async (field: string, value?: string) => {
    if (!value) {
      //@ts-ignore
      if (!values[field] || values[field] === '') {
        setErrors({ ...errors, [field]: `${field} is required` });
      } else {
        const newErrors = { ...errors };
        if (newErrors[field]) {
          delete newErrors[field];
        }
        setErrors(newErrors);
      }
    } else {
      if (value === '') {
        setErrors({ ...errors, [field]: `${field} is required` });
      } else {
        const newErrors = { ...errors };
        if (newErrors[field]) {
          delete newErrors[field];
        }
        setErrors(newErrors);
      }
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={submitPayment}
      className="p-5 font-base text-trhp-gray"
    >
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-8 mt-10">
        <TextField
          label={''}
          name={'firstName'}
          placeholder={'First Name*'}
          type={'text'}
          isDirty={dirty.firstName}
          value={values.firstName}
          hasError={!!errors.firstName}
          errorMessage={errors.firstName || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
          required
        />
        <TextField
          label={''}
          name={'lastName'}
          isDirty={dirty.lastName}
          placeholder={'Last Name*'}
          type={'text'}
          value={values.lastName}
          hasError={!!errors.lastName}
          errorMessage={errors.lastName || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
          required
        />
      </div>
      <div className="space-y-5">
        <RadioGroup
          label="Is this donation on behalf of a company?"
          radioGroup={[
            { title: 'No', value: 'false' },
            { title: 'Yes', value: 'true' }
          ]}
          name="isCompany"
          value={values.isCompany}
          hasError={!!errors.isCompany}
          errorMessage={errors.isCompany || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
        />
        {values.isCompany === 'true' && (
          <TextField
            label={''}
            name={'companyName'}
            placeholder={'Company Name*'}
            type={'text'}
            value={values.companyName}
            hasError={!!errors.companyName}
            errorMessage={errors.companyName || ''}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            validate={validateField}
            required
          />
        )}
        <TextField
          type="email"
          label={''}
          name="email"
          placeholder="Email*"
          value={values.email}
          hasError={!!errors.email}
          isDirty={dirty.email}
          errorMessage={errors.email || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
          required
        />
        <TextField
          type="text"
          label={''}
          name="address"
          placeholder="Address*"
          value={values.address}
          isDirty={dirty.address}
          hasError={!!errors.address}
          errorMessage={errors.address || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
          required
        />
        <TextField
          type="text"
          label={''}
          name="city"
          placeholder="City*"
          value={values.city}
          isDirty={dirty.city}
          hasError={!!errors.city}
          errorMessage={errors.city || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
          required
        />
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-8 mt-10">
          <Select
            label={''}
            name="state"
            placeholder="State*"
            value={values.state}
            hasError={!!errors.state}
            isDirty={dirty.state}
            errorMessage={errors.state || ''}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            required
            options={usStates.states.map(s => ({ id: s.code, name: s.state }))}
          />
          <TextField
            type="text"
            label={''}
            name="zip"
            placeholder="Zip*"
            value={values.zip}
            isDirty={dirty.zip}
            hasError={!!errors.zip}
            errorMessage={errors.zip || ''}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            validate={validateField}
            required
          />
        </div>
        <TextField
          type="text"
          label={''}
          name="phone"
          useMask={true}
          mask="1+ ###-###-####"
          placeholder="Contact Phone*"
          value={values.phone !== '' ? values.phone : undefined}
          hasError={!!errors.phone}
          errorMessage={errors.phone || ''}
          isDirty={dirty.phone}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
          required
        />
        <Checkbox
          name="anonymous"
          label="Make this donation anonymous"
          value={values.anonymous}
          hasError={!!errors.anonymous}
          errorMessage={errors.anonymous || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          validate={validateField}
        />
        <TextArea
          value={values.comment}
          setFieldValue={setFieldValue}
          rows={4}
          label=""
          placeholder={'Leave a comment'}
          name={'comment'}
        />
        <Select
          label="How did you hear about us?*"
          value={values.howDidYouHear}
          placeholder={''}
          options={[
            {
              id: 'church',
              name: 'Church'
            },
            {
              id: 'event',
              name: 'Event (Club, Conference, etc)'
            },
            {
              id: 'family',
              name: 'Family or Friend'
            },
            {
              id: 'online',
              name: 'Online'
            },
            {
              id: 'other',
              name: 'Other'
            }
          ]}
          name={'howDidYouHear'}
          required
          hasError={!!errors.howDidYouHear}
          isDirty={dirty.howDidYouHear}
          errorMessage={errors.howDidYouHear || ''}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
        {['other', 'church', 'event'].includes(values.howDidYouHear) && (
          <TextField
            type="text"
            label={'Please specify'}
            name="howDidYouHearOther"
            value={values.howDidYouHearOther}
            hasError={!!errors.howDidYouHearOther}
            errorMessage={errors.howDidYouHearOther || ''}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            validate={validateField}
            required
          />
        )}
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </div>
      <div className="mt-10 mb-6 text-center text-trhp-gray-100">
        <em>Here&apos;s what you&apos;re about to donate:</em>
      </div>
      <div className="bg-gray-100 px-6 pt-3 divide-y divide-solid -mx-5 mb-12">
        <label className="font-semibold text-lg block mb-4">
          Donation Summary
        </label>
        <div className="flex justify-between py-5">
          <div className="font-medium w-2/3">Payment Amount</div>
          <div className="w-1/3 text-right">${data.amount}</div>
        </div>
        <div className="flex justify-between py-5">
          <div className="font-medium w-3/4">
            Giving Frequency
            {!data.recurring && (
              <>
                <br />
                <span className="text-trhp-gray-100 text-sm">
                  <AiFillInfoCircle className="inline pr-1 text-lg -mt-1" />
                  Consider making this donation{' '}
                  <a
                    className="underline text-trhp-teal cursor-pointer"
                    onClick={goBack}
                  >
                    recurring
                  </a>
                </span>
              </>
            )}
          </div>
          <div className="w-1/4 text-right">
            {data.recurring ? 'Monthly' : 'One Time'}
          </div>
        </div>
        {data.coverFees && (
          <div className="flex justify-between py-5">
            <div className="font-medium w-3/4">
              Cover Donation Fees
              <br />
              <span className="text-trhp-gray-100 text-sm">
                <AiFillInfoCircle className="inline pr-1 text-lg -mt-1" />
                Ensures 100% of your donation reaches our cause
              </span>
            </div>
            <div className="w-1/4 text-right">${transactionFee}</div>
          </div>
        )}
        <div className="flex justify-between py-5">
          <div className="font-semibold w-2/3">Donation Total</div>
          <div className="w-1/3 text-right">${data.total}</div>
        </div>
      </div>
      <FixedArrowButton
        showLoader={isLoading}
        styletype="primary"
        label="Give"
        type="submit"
        className="rounded-md py-3 px-6 font-medium w-60 mb-7 text-2xl block mx-auto"
        bgOverrideColor={props.ctaColor}
      />

      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="text-red-700">
          {message}
        </div>
      )}
    </form>
  );
};
