import {
  Appearance,
  StripeElementsOptions,
  loadStripe
} from '@stripe/stripe-js';
import { DonationFormProps } from './types';
import { Elements } from '@stripe/react-stripe-js';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PaymentForm } from './PaymentForm';
import { calculateFee } from 'utils/payments';
import { useEffect } from 'react';

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ||
    'pk_live_AKawJHgWeHFkSzXXVgjqScSV'
);

export const DonationForm = (props: DonationFormProps) => {
  const { clientSecret, setClientSecret, ctaColor } = props;
  const appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#696969',
      colorDanger: '#df1b41',
      fontFamily: 'Montserrat, Helvetica, Arial, Lucida, sans-serif',
      spacingUnit: '2.5px',
      borderRadius: '4px',
      fontSizeBase: '14px'
    }
  };
  useEffect(() => {
    if (clientSecret) return;
    // Create PaymentIntent as soon as the page loads
    fetch('/api/donations/create-payment-intent/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...props.data })
    })
      .then(res => res.json())
      .then(data => {
        setClientSecret(data.clientSecret);
      });
  }, []);

  if (!clientSecret) return <LoadingSpinner variant="primary" size="lg" />;

  let total = props.data.amount;
  const transactionFee = calculateFee(total);
  if (props.data.coverFees) {
    total += transactionFee;
  }

  total = total * 100;

  const options: StripeElementsOptions = {
    clientSecret,
    // mode: 'subscription',
    // currency: 'usd',
    // amount: total,
    appearance
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <PaymentForm
        data={{ ...props.data, total: total / 100 }}
        goBack={props.goBack}
        slug={props.slug}
        ctaColor={ctaColor}
      />
    </Elements>
  );
};
