import { ReactElement } from 'react';
import { useS3Upload } from 'hooks/useS3Upload';
import Parser from 'html-react-parser';

const parseFileUrl = async (html: string): Promise<string> => {
  const { getFileUrl } = useS3Upload({
    bucket: process.env.NEXT_PUBLIC_S3_BUCKET || 'trhp'
  });
  const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi;
  const match = html.match(linkRx);
  if (!match) return html;
  let m;
  let parsed = html;
  while ((m = linkRx.exec(html)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === linkRx.lastIndex) {
      linkRx.lastIndex++;
    }

    const link = m[2];

    if (link.includes('http') || link.includes('mailto')) continue;
    const fileUrl = await getFileUrl(link);
    parsed = parsed.replace(link, fileUrl);
  }
  return parsed;
};

export const parseHtml = async (
  html: string
): Promise<string | ReactElement | ReactElement[]> => {
  const replaced = await parseFileUrl(html);
  return Parser(replaced);
};
