import { FetchService } from './FetchService';
import type { PresignedUrlProps } from 'modules/s3/types';

export const getUploadUrl = async (props: PresignedUrlProps) =>
  FetchService<string, PresignedUrlProps>('GET', '/api/s3/upload-url/', props);

export const getPresignedUrl = async (props: PresignedUrlProps) =>
  FetchService<string, PresignedUrlProps>('GET', '/api/s3/file-url/', props);

export const deleteUploadedFile = async (props: PresignedUrlProps) =>
  FetchService<string, PresignedUrlProps>(
    'DELETE',
    '/api/s3/delete-file/',
    props
  );
